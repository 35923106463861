// helpers
import LoadImage from '@/Views/_Components/Helpers/Image/index.vue'

// icons
import { XIcon } from 'vue-feather-icons';

export default {
  name: "User",

  props: {
    hasHeader: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  components: {
    'oston-image': LoadImage,

    // icons
    'feather-icon-close': XIcon
  }
}
